//! moment.js locale configuration
//! locale : finnish (fi)
//! author : Tarmo Aidantausta : https://github.com/bleadof

import moment from 'moment';

import type { TrelloWindow } from '@trello/window-types';

declare const window: TrelloWindow;

import { makeYearAwareCalendar } from './makeYearAwareCalendar';

const numbersPast = [
  'nolla',
  'yksi',
  'kaksi',
  'kolme',
  'neljä',
  'viisi',
  'kuusi',
  'seitsemän',
  'kahdeksan',
  'yhdeksän',
];
const numbersFuture = [
  'nolla',
  'yhden',
  'kahden',
  'kolmen',
  'neljän',
  'viiden',
  'kuuden',
  numbersPast[7],
  numbersPast[8],
  numbersPast[9],
];

function translate(num: any, withoutSuffix: any, key: any, isFuture: any) {
  let result = '';
  switch (key) {
    case 's':
      return isFuture ? 'muutaman sekunnin' : 'muutama sekunti';
    case 'm':
      return isFuture ? 'minuutin' : 'minuutti';
    case 'mm':
      result = isFuture ? 'minuutin' : 'minuuttia';
      break;
    case 'h':
      return isFuture ? 'tunnin' : 'tunti';
    case 'hh':
      result = isFuture ? 'tunnin' : 'tuntia';
      break;
    case 'd':
      return isFuture ? 'päivän' : 'päivä';
    case 'dd':
      result = isFuture ? 'päivän' : 'päivää';
      break;
    case 'M':
      return isFuture ? 'kuukauden' : 'kuukausi';
    case 'MM':
      result = isFuture ? 'kuukauden' : 'kuukautta';
      break;
    case 'y':
      return isFuture ? 'vuoden' : 'vuosi';
    case 'yy':
      result = isFuture ? 'vuoden' : 'vuotta';
      break;
    default:
      result = '';
  }
  result = `${verbalNumber(num, isFuture)} ${result}`;

  return result;
}

function verbalNumber(num: any, isFuture: any) {
  return num < 10 ? (isFuture ? numbersFuture[num] : numbersPast[num]) : num;
}

moment.locale(window.locale, {
  months: [
    'tammikuu',
    'helmikuu',
    'maaliskuu',
    'huhtikuu',
    'toukokuu',
    'kesäkuu',
    'heinäkuu',
    'elokuu',
    'syyskuu',
    'lokakuu',
    'marraskuu',
    'joulukuu',
  ],
  monthsShort: [
    'tammi',
    'helmi',
    'maalis',
    'huhti',
    'touko',
    'kesä',
    'heinä',
    'elo',
    'syys',
    'loka',
    'marras',
    'joulu',
  ],
  weekdays: [
    'sunnuntai',
    'maanantai',
    'tiistai',
    'keskiviikko',
    'torstai',
    'perjantai',
    'lauantai',
  ],
  weekdaysShort: ['su', 'ma', 'ti', 'ke', 'to', 'pe', 'la'],
  weekdaysMin: ['su', 'ma', 'ti', 'ke', 'to', 'pe', 'la'],
  longDateFormat: {
    LT: 'HH.mm',
    LTS: 'HH.mm.ss',
    L: 'DD.MM.YYYY',
    LL: 'Do MMMM[ta] YYYY',
    LLL: 'Do MMMM[ta] YYYY, [klo] HH.mm',
    LLLL: 'Do MMMM',
    l: 'D.M.YYYY',
    ll: 'Do MMM YYYY',
    lll: 'Do MMM YYYY, [klo] HH.mm',
    llll: 'Do MMMM',
  },
  // see `app/src/components/DateRangePicker/getLocalizedPlaceholder.ts`
  placeholder: {
    l: 'P.K.VVVV',
    LT: 'T.mm',
  },
  calendar: makeYearAwareCalendar({
    sameDay: '[tänään] [klo] LT',
    nextDay: '[huomenna] [klo] LT',
    nextWeek: 'dddd [klo] LT',
    lastDay: '[eilen] [klo] LT',
    lastWeek: '[viime] dddd[na] [klo] LT',
    sameYear: 'llll [klo] LT',
    sameElse: 'll [klo] LT',
  }),
  relativeTime: {
    future: '%s päästä',
    past: '%s sitten',
    s: translate,
    m: translate,
    mm: translate,
    h: translate,
    hh: translate,
    d: translate,
    dd: translate,
    M: translate,
    MM: translate,
    y: translate,
    yy: translate,
  },
  ordinalParse: /\d{1,2}\./,
  // @ts-expect-error TS(2322): Type 'string' is not assignable to type '(n: numbe... Remove this comment to see the full error message
  ordinal: '%d.',
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
});
